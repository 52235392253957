import { CenteredDiv } from "./CenteredDiv";
import { Box } from "@mui/material";
import { getCopy } from "../../authoring/copy";
import { CustomButton } from "../Dashboards/shared/Button";
import Page from "../shared/Page/Page";
import { useNavigate } from "react-router-dom";
import { getHome } from "../Auth/AuthHelper";
import { getUser } from "../Auth/AuthProvider";
import { environmentVariables } from "../../config";

export function EstimateErrorPage() {
  const nav = useNavigate();
  const copy = getCopy().createEstimate.errorPage;
  const user = getUser();
  return (
    <Page>
      <CenteredDiv className="mt-10 overflow-auto">
        <Box boxShadow={2} sx={{ p: "50px", width: "530px" }}>
          <h2 className="text-2xl text-center font-medium text-aa-dark-purple pb-7">{copy.header}</h2>
          <p className="text-center text-aa-dark-grey pb-20">{copy.message}</p>
          <div className="flex flex-col justify-center">
            <a href={environmentVariables.aeUrl} target="_blank" className="text-center" rel="noreferrer">
              <CustomButton variant="text" sx={{ mr: "15px", fontWeight: "500", color: "#5132A2" }}>
                {copy.backToApproveEngineButton}
              </CustomButton>
            </a>
            <CustomButton
              variant="text"
              sx={{ mr: "15px", fontWeight: "500", color: "#5132A2" }}
              onClick={() => {
                nav(getHome(user));
              }}
            >
              {copy.backToTitlesEngineButton}
            </CustomButton>
          </div>
        </Box>
      </CenteredDiv>
    </Page>
  );
}
