import { createContext, ReactNode, useContext, useState } from "react";
import { useOperations } from "../hook/useOperations";
import { TitleSpecialistDashboardContextType } from "./types";
import { useTitleSpecialistDashboardFilter, useTitleSpecialistDashboardSort } from "../../../Hooks/GridCollation";

const TitleSpecialistDashboardContext = createContext<TitleSpecialistDashboardContextType>(null!);

export function useTitleSpecialistDashboard(): TitleSpecialistDashboardContextType {
  return useContext(TitleSpecialistDashboardContext);
}

export function TitleSpecialistDashboardProvider({ children }: { children: ReactNode }) {
  const {
    allRows,
    setAllRows,
    dashboardRows,
    setDashboardRows,
    setDashboardUpdate,
    searchTerm,
    loading,
    setLoading,
    dashboardUpdate,
  } = useOperations();
  const [filterModel, setFilterModel] = useTitleSpecialistDashboardFilter();
  const [showCompleted, setShowCompleted] = useState(false);
  const [sortOptions, setSortOptions] = useTitleSpecialistDashboardSort();

  const value = {
    allRows,
    setAllRows,
    dashboardRows,
    setDashboardRows,
    searchTerm,
    setDashboardUpdate,
    filterModel,
    setFilterModel,
    sortOptions,
    setSortOptions,
    loading,
    setLoading,
    dashboardUpdate,
    showCompleted,
    setShowCompleted,
  };

  return <TitleSpecialistDashboardContext.Provider value={value}>{children}</TitleSpecialistDashboardContext.Provider>;
}
