import { useCallback, useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTime } from "luxon";
import { UiEstimateTemplateField, TitleOrderField } from "../types";
import { CreateEstimateTitleOrder } from "../../../../../networking/core/types";
import { EstimateTemplateField } from "../../../../../contexts/estimates/core/types";
import { CreateEstimateValidationResult, validator } from "../validateFields";
import { useFlags } from "launchdarkly-react-client-sdk";

export const DynamicEstimateTemplateField = (props: {
  field: EstimateTemplateField;
  disabled?: boolean;
  knownFields: TitleOrderField[];
  unknownFields: UiEstimateTemplateField[];
  titleOrder: CreateEstimateTitleOrder | undefined;
  onValueChange?: (value: TitleOrderField, titleOrder: CreateEstimateTitleOrder) => void;
}) => {
  const flags = useFlags();
  const isTypeOfPurchaseFlagOn = flags.typeOfPurchaseWklsTemplateField;
  const updateFieldState = (value?: string) => {
    if (props.titleOrder) {
      if (titleOrderField) {
        titleOrderField.setValue(props.titleOrder, value);
        const updatedValidationState = validator[titleOrderField.estimateTemplateField.fieldInfo.type](
          titleOrderField.estimateTemplateField,
          titleOrderField.getValue(props.titleOrder) ?? ""
        );
        titleOrderField.isValid = updatedValidationState.valid;
        setInitialValidationState(updatedValidationState);
        if (isTypeOfPurchaseFlagOn && props.onValueChange) {
          props.onValueChange(titleOrderField, props.titleOrder);
        }
        return;
      }
    }
    if (unknownField) {
      unknownField.value = value;
      const updatedValidationState = validator[unknownField.fieldInfo.type](unknownField, unknownField.value ?? "");
      unknownField.isValid = updatedValidationState?.valid;
      setInitialValidationState(updatedValidationState);
    }
  };

  const getFieldValueFromState = () => {
    if (titleOrderField) {
      return titleOrderField.getValue(props.titleOrder);
    }
    if (unknownField) {
      return unknownField.value;
    }
    return "";
  };

  const fieldName = props.field.name;
  const groupName = props.field.teGroup;
  const titleOrderField = props.knownFields.find(
    (field) => fieldName === field.estimateTemplateField.name && groupName === field.estimateTemplateField.teGroup
  );
  const [unknownField, setUnknownField] = useState(props.unknownFields.find((field) => fieldName === field.name && groupName === field.teGroup));

  const [val, setVal] = useState<string | undefined>(getFieldValueFromState());

  const getValidationState = useCallback(() => {
    if (titleOrderField) {
      return validator[titleOrderField.estimateTemplateField.fieldInfo.type](
        titleOrderField.estimateTemplateField,
        titleOrderField.getValue(props.titleOrder) ?? ""
      );
    }
    if (unknownField) {
      return validator[unknownField.fieldInfo.type](unknownField, unknownField.value ?? "");
    }
    return undefined;
  }, [props.titleOrder, titleOrderField, unknownField]);

  const [validationState, setInitialValidationState] = useState<CreateEstimateValidationResult | undefined>(getValidationState());
  useEffect(() => {
      setUnknownField(props.unknownFields.find((field) => fieldName === field.name && groupName === field.teGroup));
      setInitialValidationState(getValidationState());
  }, [getValidationState, setUnknownField, props.unknownFields, groupName, fieldName]);

  switch (props.field.fieldInfo.type) {
    case "text":
      return (
        <FormControl>
          <TextField
            id={`text-field-test-id-${groupName}-${fieldName}`}
            error={validationState?.valid === false}
            helperText={validationState?.valid === false ? validationState.message : ""}
            disabled={props.disabled}
            label={fieldName}
            variant="outlined"
            onChange={(event) => {
              updateFieldState(event.target.value);
              setVal(event.target.value);
            }}
            value={val ?? ""}
            inputProps={{
              "data-testid": `estimate-text-field-${groupName}-${fieldName}`,
            }}
          />
        </FormControl>
      );
    case "number":
      return (
        <FormControl>
          <TextField
            id={`text-field-number-test-id-${groupName}-${fieldName}`}
            error={validationState?.valid === false}
            helperText={validationState?.valid === false ? validationState.message : ""}
            disabled={props.disabled}
            label={fieldName}
            variant="outlined"
            type="number"
            onChange={(event) => {
              updateFieldState(event.target.value);
              setVal(event.target.value);
            }}
            value={val ?? ""}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              "data-testid": `estimate-number-field-${groupName}-${fieldName}`,
            }}
          />
        </FormControl>
      );
    case "boolean":
      return (
        <FormControl disabled={props.disabled} error={validationState?.valid === false}>
          <RadioGroup
            value={val}
            aria-label={fieldName}
            data-testid={`estimate-boolean-field-${groupName}-${fieldName}`}
            onChange={(event) => {
              updateFieldState(event.target.value);
              setVal(event.target.value);
            }}
            defaultChecked={false}
          >
            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
            <FormControlLabel value={"No"} control={<Radio />} label="No" />
          </RadioGroup>
          <FormHelperText>
            {validationState?.valid === false ? validationState.message : ""}
          </FormHelperText>
        </FormControl>
      );
    case "date":
      return (
        <FormControl disabled={props.disabled} error={validationState?.valid === false}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled={props.disabled}
              label={fieldName}
              aria-label={fieldName}
              inputFormat="MM/dd/yyyy"
              renderInput={(params) => (
                <TextField {...params} error={validationState?.valid === false} data-testid={`estimate-date-field-${groupName}-${fieldName}`} />
              )}
              onChange={(event) => {
                if (!event) {
                  updateFieldState("");
                  setVal("");
                } else {
                  setVal(event);
                  const enteredDate = DateTime.fromJSDate(new Date(event), { zone: "UTC" });
                  updateFieldState(enteredDate.toISO() ?? undefined);
                }
              }}
              value={val ?? ""}
            />
          </LocalizationProvider>
          <FormHelperText>
            {validationState?.valid === false ? validationState.message : ""}
          </FormHelperText>
        </FormControl>
      );
    case "choice":
      return (
        <FormControl disabled={props.disabled} error={validationState?.valid === false}>
          <InputLabel id={fieldName}>{fieldName}</InputLabel>
          <Select
            labelId={fieldName}
            label={fieldName}
            aria-label={fieldName}
            data-testid={`estimate-choice-field-${groupName}-${fieldName}`}
            className="w-56"
            onChange={(event) => {
              updateFieldState(event.target.value);
              setVal(event.target.value);
            }}
            value={val ?? ""}
          >
            {props.field.fieldInfo.options.map((option) => (
              <MenuItem key={option} value={option} data-testid="select-option">
                {option}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {validationState?.valid === false ? validationState.message : ""}
          </FormHelperText>
        </FormControl>
      );
    default:
      return null;
  }
};
