import { Static, Type } from "@sinclair/typebox";
import addFormat from "ajv-formats";
import Ajv from "ajv/dist/2020";
import { AeDealTypeEnum, AeTitleVendorTypeEnum, StatusSeverity } from "../core/types";

export const ajv = addFormat(new Ajv({}), ["date-time"]).addKeyword("kind").addKeyword("modifier");

export type TitlesEngineAddress = Static<typeof TitlesEngineAddressSchema>
const TitlesEngineAddressSchema = Type.Object({
  createdAt: Type.Optional(Type.String({ format: "date-time" })),
  street1: Type.String(),
  street2: Type.String(),
  state: Type.String(),
  city: Type.String(),
  zip: Type.String(),
});

export enum BorrowerRelationship {
  JOINT_AND_SPOUSAL = "JOINT_AND_SPOUSAL",
  COSIGNER_NON_SPOUSAL = "COSIGNER_NON_SPOUSAL",
  SPOUSE = "SPOUSE",
  PARTNER = "PARTNER",
  SIBLING = "SIBLING",
  PARENT = "PARENT",
  CHILD = "CHILD",
}

export type TitlesEngineCustomer = Static<typeof TitlesEngineCustomerSchema>;
const TitlesEngineCustomerSchema = Type.Object({
  createdAt: Type.String({ format: "date-time" }),
  updatedAt: Type.String({ format: "date-time" }),
  address: TitlesEngineAddressSchema,
  firstName: Type.String(),
  middleName: Type.Optional(Type.String()),
  lastName: Type.String(),
  birthday: Type.Optional(Type.String({ format: "date-time" })),
  email : Type.Optional(Type.String()),
  homePhone: Type.Optional(Type.String()),
  phone: Type.Optional(Type.String()),
  type: Type.Optional(Type.String()),
  ssn: Type.Optional(Type.String()),
  driversLicenseNum: Type.Optional(Type.String()),
  federalEmployerId: Type.Optional(Type.String()),
  relationshipToPrimary: Type.Optional(Type.Enum(BorrowerRelationship)),
});

export type TitlesEngineLienHolder = Static<typeof TitlesEngineLienHolderSchema>;
const TitlesEngineLienHolderSchema = Type.Object({
  createdAt: Type.Optional(Type.String({ format: "date-time" })),
  updatedAt: Type.Optional(Type.String({ format: "date-time" })),
  id: Type.Number(),
  address: TitlesEngineAddressSchema,
  name: Type.String(),
  phone: Type.Optional(Type.String()),
  lienDate: Type.Optional(Type.String({ format: "date-time" })),
  eltNum: Type.Optional(Type.String()),
  requestedDueDate: Type.Optional(Type.String({ format: "date-time" })),
});
export type TitlesEngineLender = Static<typeof TitlesEngineLenderSchema>;
const TitlesEngineLenderSchema = Type.Object({
  id: Type.Optional(Type.Number()),
  createdAt: Type.Optional(Type.String({ format: "date-time" })),
  updatedAt: Type.Optional(Type.String({ format: "date-time" })),
  aeLenderId: Type.String(),
  name: Type.String(),
  phone: Type.Union([Type.String(), Type.Null()]),
  eltNum: Type.Union([Type.String(), Type.Null()]),
  address: TitlesEngineAddressSchema,
  isActive: Type.Boolean()
});

export type TitlesEngineUserPreDb = Static<typeof TitlesEngineUserPreDbSchema>;
const TitlesEngineUserPreDbSchema = Type.Object({
  aeUserId: Type.Optional(Type.String()),
  email: Type.String(),
  firstName: Type.String(),
  lastName: Type.String(),
  role: Type.Optional(Type.Union([Type.Null(), Type.String()])),
});

export type AELeadStatus = number;

export type TitlesEngineTitleOrder = Static<typeof _TitlesEngineTitleOrderSchema>
const _TitlesEngineTitleOrderSchema = Type.Object({
  id: Type.Optional(Type.Number()),
  teOrderId: Type.Optional(Type.String()),
  aeLeadId: Type.String(),
  aeDealId: Type.Optional(Type.String()),
  aeRecordId: Type.Number(),
  fundedDate: Type.Optional(Type.String()),
  createTimeStamp: Type.String(),
  updateTimeStamp: Type.String(),
  lenderName: Type.Optional(Type.String()),
  state: Type.Optional(Type.String()),
  transactionType: Type.Optional(Type.String()),
  eta: Type.Optional(Type.String()),
  duplicateTitleEta: Type.Optional(Type.String()),
  assignedAssociateTitleSpecialistId: Type.Optional(Type.String()),
  lastTouched: Type.Optional(Type.String()),
  newAeStatus: Type.Number(),
  aeStatusTimeStamp: Type.String(),
  statusSeverity: Type.Optional(Type.Enum(StatusSeverity)),
  followUpDate: Type.Optional(Type.String()),
  dealType: Type.Optional(Type.Union([Type.Null(), Type.Enum(AeDealTypeEnum)])),
  applicationId: Type.Optional(Type.Union([Type.Null(), Type.String()])),
  vendor: Type.Optional(Type.Union([Type.Enum(AeTitleVendorTypeEnum), Type.Null()])),
  vendorFee: Type.Optional(Type.Union([Type.Number(), Type.Null()]))
});

export type TitlesEngineVehicle = Static<typeof TitlesEngineVehicleSchema>;
const TitlesEngineVehicleSchema = Type.Object({
  vin: Type.String(),
  createdAt: Type.String({ format: "date-time" }),
  updatedAt: Type.String({ format: "date-time" }),
  year: Type.Optional(Type.Integer()),
  make: Type.Optional(Type.String()),
  model: Type.Optional(Type.String()),
  isTrailer: Type.Optional(Type.Boolean()),
  mileage: Type.Optional(Type.Integer()),
  buyerSellerRelationship: Type.Optional(Type.String()),
  isGift: Type.Optional(Type.Boolean()),
  currentState: Type.Optional(Type.String()),
  leased: Type.Optional(Type.Boolean()),
  taxPaymentType: Type.Optional(Type.String()),
  titleOrMso: Type.Optional(Type.String()),
  color: Type.Optional(Type.String()),
  fuelType: Type.Optional(Type.String()),
  purchasePrice: Type.Optional(Type.Number()),
  purchaseDate: Type.Optional(Type.String({ format: "date-time" })),
  purchaseType: Type.Optional(Type.String()),
  grossWeightInPounds: Type.Optional(Type.Number()),
  emptyWeightInPounds: Type.Optional(Type.Number()),
  lengthInFeet: Type.Optional(Type.Number()),
  lengthOfTrailerInFeet: Type.Optional(Type.Number()),
  numberOfWheels: Type.Optional(Type.Integer()),
  taxExempt: Type.Optional(Type.Boolean()),
  taxExemptReason: Type.Optional(Type.String()),
  taxesPaidToDealer: Type.Optional(Type.Number()),
  tradeInAmount: Type.Optional(Type.Number()),
  additionalFees: Type.Optional(Type.Number()),
  mcNumberForIrpPlate: Type.Optional(Type.String()),
  licensePlateNumber: Type.Optional(Type.String()),
});

export type TitlesEngineTitleOrderWithAssociations = Static<typeof TitlesEngineTitleOrderWithAssociationsSchema> &
  Static<typeof _TitlesEngineTitleOrderSchema>;
const TitlesEngineTitleOrderWithAssociationsSchema = Type.Object({
  assignedTitleSpecialist: Type.Optional(TitlesEngineUserPreDbSchema),
  primaryCustomer: Type.Optional(TitlesEngineCustomerSchema),
  secondaryCustomer: Type.Optional(TitlesEngineCustomerSchema),
  lienHolder: TitlesEngineLienHolderSchema,
  lender: Type.Optional(TitlesEngineLenderSchema),
  vehicle: TitlesEngineVehicleSchema,
});

export function isTitlesEngineTitleOrderWithAssociations(data: unknown): data is TitlesEngineTitleOrderWithAssociations {
  const valid = ajv.validate(TitlesEngineTitleOrderWithAssociationsSchema, data);
  if (!valid) {
    console.log(`${JSON.stringify(ajv.errors)}`);
  }
  return valid;
}
