import { UserProfile } from "../UserProfile/UserProfile";
import { useAuth } from "../Auth/AuthProvider";
import { User } from "../Auth/Auth";
import LogoSvg from "../../assets/auto_approve_logo_full_color.svg";
import { AppBar, Badge, Box, CircularProgress, Drawer, IconButton, Toolbar, Typography } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { useEffect, useMemo, useState } from "react";
import { Close } from "@mui/icons-material";
import { getCopy } from "../../authoring/copy";
import { NotificationItem } from "./NotificationItem";
import { NotificationEvent, NotificationStatus } from "../../networking/core/types";
import { getNotificationEvents, markAllNotificationsAsRead } from "../../networking/TitlesWorkflowAPI";
import { IconBarButton } from "../shared/IconBarButton/IconBarButton";
import { CustomButton } from "../Dashboards/shared/Button";

const { appHeader } = getCopy();

export function AppHeader() {
  const { user } = useAuth();

  return (
    <AppBar
      position="relative"
      color={"transparent"}
      sx={{ boxShadow: 0, borderBottom: "2px solid rgba(243,244,246)" }}
    >
      <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
        <img src={LogoSvg} alt="Auto Approve Logo" />
        {user && <MainHeaderActions user={user} />}
      </Toolbar>
    </AppBar>
  );
}

type MainHeaderActionsProps = {
  user: User;
};

function MainHeaderActions(props: MainHeaderActionsProps) {
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationEvent[]>([]);
  const [loadingMarkNotificationsAsRead, setLoadingMarkNotificationsAsRead] = useState(false);
  const { user } = useAuth();

  const unreadNotificationsCount = useMemo(
    () => notifications.filter((notification) => notification.status === NotificationStatus.UNREAD).length,
    [notifications]
  );

  useEffect(() => {
    if (!user) return;

    getNotificationEvents(user.userId, user.token)
      .then((result) => setNotifications(result))
      .catch((err) => {
        console.error("Error fetching notifications", err);
      });
  }, [user]);

  const onCloseNotificationDrawer = () => {
    setNotificationDrawerOpen(false);
  };

  const onClickNotificationIcon = () => {
    setNotificationDrawerOpen(true);
  };

  const onClickDrawerClose = () => {
    setNotificationDrawerOpen(false);
  };

  async function handleMarkAllRead() {
    if (!user) return;
    const { userId, token } = user;
    setLoadingMarkNotificationsAsRead(true);

    markAllNotificationsAsRead(userId, token)
      .then(() => {
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) => ({
            ...notification,
            status: NotificationStatus.READ,
          }))
        );
      })
      .finally(() => setLoadingMarkNotificationsAsRead(false));
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconBarButton dataTestId="notifications-button" onClick={onClickNotificationIcon} tooltipText="Notifications">
          {unreadNotificationsCount === 0 ? (
            <NotificationsOutlinedIcon fontSize={"medium"} />
          ) : (
            <Badge
              variant="dot"
              sx={{
                "& .MuiBadge-badge": {
                  color: "#5132A2",
                  backgroundColor: "#5132A2",
                  top: 2,
                  right: 4,
                },
              }}
              data-testid="notifications-badge"
            >
              <NotificationsOutlinedIcon fontSize={"medium"} />
            </Badge>
          )}
        </IconBarButton>
        <UserProfile user={props.user} />
      </Box>

      <Drawer
        open={notificationDrawerOpen}
        anchor="right"
        ModalProps={{ onBackdropClick: onCloseNotificationDrawer }}
        PaperProps={{ sx: { width: "40vw" }, id: "base-drawer" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            py: 4,
            pl: 3,
            pr: 4,
            alignItems: "center",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            {appHeader.notifications.title}
          </Typography>
          <IconButton onClick={onClickDrawerClose} data-testid="close-notifications-button">
            <Close />
          </IconButton>

          <CustomButton
            variant="text"
            sx={{
              fontSize: 12,
              fontWeight: 400,
              position: "absolute",
              bottom: 8,
              right: 35,
            }}
            disabled={loadingMarkNotificationsAsRead}
            onClick={handleMarkAllRead}
          >
            {loadingMarkNotificationsAsRead && <CircularProgress className="m-auto mr-2" size={20} thickness={8} />}
            {appHeader.notifications.markAllReadButton.label}
          </CustomButton>
        </Box>
        {notifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </Drawer>
    </>
  );
}
