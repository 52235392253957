import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../App";
import { validateToken } from "../../networking/auth/TitlesWorkflowAuthApi";
import { convertUserTokenToUser } from "../Auth/Auth";
import { useEffect } from "react";
import { useAuth } from "../Auth/AuthProvider";
import { CircularProgress } from "@mui/material";

export function LandingPage() {
  const { updateUser } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const authToken = searchParams.get("authToken");
    const expiresIn = Number(searchParams.get("expiresIn"));
    const route = searchParams.get("route");
    const passThroughParams = Object.fromEntries(
      Object.entries(Object.fromEntries(searchParams)).filter(
        ([key, value]) => !["authToken", "route", "expiresIn"].includes(key) && value
      )
    );

    const urlParams = Object.entries(passThroughParams)
      .map(([key, value]) => {
        return `${key}=${value}`;
      })
      .join("&");

    if (authToken && route && !isNaN(expiresIn)) {
      validateToken(authToken, expiresIn)
        .then((userToken) => {
          updateUser(convertUserTokenToUser(undefined, userToken));
          navigate(`/${route.replace("/", "")}?${urlParams}`, {
            state: { search: passThroughParams, from: location.href },
          });
        })
        .catch(() => {
          navigate(ROUTES.ROOT, {
            state: { from: location.href },
          });
        });
    } else {
      navigate(ROUTES.ROOT, {
        state: { from: location.href },
      });
    }
  }, [navigate, searchParams, updateUser]);

  return <div style={{
    position: "fixed",
    top: 0,
    zIndex: 9999,
    background: "white",
    height: "100vh",
    width: "100%"
  }}>
    {<div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%"
    }}>
      <CircularProgress />
    </div>}
  </div>;
}
