import { RecipeCardWithDocument, ReturnedRecipeCardType, RecipeCardModel as SharedRecipeCardModel, UpdateRecipeCardVisibilityRequest } from "@auto-approve/auto-approve-ui-library";
import axios, { AxiosRequestConfig } from "axios";
import { WORKFLOW_HOST_URL } from "../core/types";
import { RecipeCardModel } from "./RecipeCardServiceTypes";

export const createRecipeCards = async (
  leadOid: string,
  recipeCards: Array<SharedRecipeCardModel>,
  signal: AbortSignal,
  token: string,
) => {
  const url = `${WORKFLOW_HOST_URL}/stipulations/v2/recipe-cards`;
  const body: Array<RecipeCardModel> = recipeCards.map(recipeCard => ({
    leadOid: leadOid,
    ...recipeCard
}));
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token
    },
    signal
  };

  return axios
    .post<Array<ReturnedRecipeCardType>>(url, body, config)
    .then(response =>{
      if (response) {
        return response.data;
      }
      return Promise.reject("Unable to create document type.");
    });
};

export const deleteRecipeCard = async (
  recipeCardId: number,
  signal: AbortSignal,
  token: string,
  documentId?: string
) => {
  const url = `${WORKFLOW_HOST_URL}/stipulations/v2/recipe-cards/${recipeCardId}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token
    },
    params: {
      documentId
    },
    signal
  };
  return axios
  .delete(url, config)
  .then(response => {
    return response;
  });
};

export const getRecipeCards = (
  leadOid: string,
  lender: string,
  signal: AbortSignal,
  token: string
): Promise<Array<RecipeCardWithDocument>> => {
  const url = `${WORKFLOW_HOST_URL}/v2/recipe-cards`;
    const config: AxiosRequestConfig = {
        headers: {
            Authorization: token
        },
        params: {
          leadOid,
          lender
        },
        signal
    };
  return axios
  .get<Array<RecipeCardWithDocument>>(url, config)
  .then((response) => {
      if (response.data) {
          return response.data;
      }
      return Promise.reject("Unable to retrieve documents and recipe cards.");
  });
};

export const updateRecipeCardsVisibilityCustomerPortal = async (
  recipeCards: Array<UpdateRecipeCardVisibilityRequest>,
  leadOid: string,
  signal: AbortSignal,
  token: string,
) => {
  const url = `${WORKFLOW_HOST_URL}/stipulations/v2/recipe-cards/customer-portal`;
  const body: Array<UpdateRecipeCardVisibilityRequest> = recipeCards.map(recipeCard => ({
      ...recipeCard,
      leadOid: leadOid,
  }));
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token
    },
    signal
  };

  const response =  await axios.put<Array<ReturnedRecipeCardType>>(url, body, config);
  if (response) {
    return response.data;
  }
  return Promise.reject("Unable to update recipe cards visibility.");
};
