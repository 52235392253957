import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./msal-config";
import { LicenseInfo as MuiXLicenseInfo } from "@mui/x-license-pro";
import { environmentVariables } from "./config";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { BrowserRouter } from "react-router-dom";

MuiXLicenseInfo.setLicenseKey(environmentVariables.muiPremiumLicenseKey);

export const msalInstance = new PublicClientApplication(msalConfig);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: environmentVariables.launchDarklyClientSideID,
  });

  createRoot(document.getElementById("root")!).render(
    <StrictMode>
      <LDProvider>
        <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
          <App msalInstance={msalInstance} />
        </BrowserRouter>
      </LDProvider>
    </StrictMode>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
