import { useEffect, useState } from "react";
import { getCopy } from "../../../authoring/copy";
import { TitleOrderEscalationDashboardData } from "../../../networking/core/types";
import Page from "../../shared/Page/Page";
import { LinkCell } from "../../shared/Tables/LinkCell";
import { useEscalationsDashboard } from "../context/EscalationsDashboardContext";
import { DashboardHeader } from "../shared/DashboardHeader";
import { formatTotalDays } from "../shared/FieldFormats";
import { DaysInStatusCell } from "../shared/StatusSeverity";
import { FlagCell } from "./FlagCell";
import { EscalationsDetailsDrawer } from "./EscalationsDetailsDrawer";
import { EscalationIndicator } from "./EscalationIndicator";
import { useAuth } from "../../Auth/AuthProvider";
import { getEscalationDashboardData } from "../../../networking/TitlesWorkflowAPI";
import { EscalationDashboardTable } from "./EscalationsDashboardTable";
import { EscalationsDashboardColumns, OrderWithEscalation } from "./types";
import { formatAeLeadStatus } from "@auto-approve/auto-approve-ui-library";

export function EscalationsDashboard() {
  const { allRows, setAllRows, setLoading } = useEscalationsDashboard();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<OrderWithEscalation | undefined>(undefined);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;

    setLoading(true);
    getEscalationDashboardData(user.token)
      .then((response: TitleOrderEscalationDashboardData) => {
        setAllRows(
          response.titleOrders.map((titleOrder) => ({
            ...titleOrder,
            id: titleOrder.aeLeadId,
          }))
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, setAllRows, setLoading]);

  const onCellClick = (row: OrderWithEscalation) => {
    setDrawerOpen(true);
    setSelectedRow(row);
  };

  return (
    <Page>
      <DashboardHeader />
      <EscalationDashboardTable
        getColumns={getColumns}
        defaultPageSize={10}
        rowsPerPageOptions={[10, 15, 20]}
        rows={allRows}
        totalRows={allRows.length}
        filterMode="client"
        sortingMode="client"
        paginationMode="client"
        useData={useEscalationsDashboard}
        onCellClick={onCellClick}
      />
      <EscalationsDetailsDrawer isOpen={isDrawerOpen} onClose={() => setDrawerOpen(false)} row={selectedRow} />
    </Page>
  );
}

export function getColumns(): EscalationsDashboardColumns {
  const { columnLabels } = getCopy().titleOrdersDashboard;

  const columns: EscalationsDashboardColumns = [
    {
      headerName: "",
      field: "escalation.acceptedAt",
      flex: 0.2,
      renderCell: (params) => <EscalationIndicator acceptedAt={params.row.escalation.acceptedAt || ""} />,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      headerName: columnLabels.recordId,
      field: "recordId" as const,
      flex: 1,
      renderCell: (params) => <LinkCell row={params.row} />,
      disableColumnMenu: true,
      sortable: true,
    },
    {
      headerName: columnLabels.received,
      field: "escalation.requestedAt",
      flex: 2,
      renderCell: (params) => <div>{params.row.escalation.requestedAt}</div>,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      headerName: columnLabels.titleRep,
      field: "assignee",
      flex: 2,
      renderCell: (params) => {
        const { row } = params;
        return row.owner ? `${row.owner.firstName}, ${row.owner.lastName}` : "Unassigned";
      },
      disableColumnMenu: true,
      sortable: false,
    },
    {
      headerName: columnLabels.customer,
      field: "customerName",
      flex: 2,
      renderCell: (params) => {
        const { row } = params;
        return row.customer ? `${row.customer.lastName}, ${row.customer.firstName}` : "";
      },
      disableColumnMenu: true,
      sortable: true,
    },
    {
      headerName: columnLabels.vin,
      field: "vin",
      flex: 2,
      disableColumnMenu: true,
      sortable: true,
    },
    {
      headerName: columnLabels.lenderName,
      field: "lenderName",
      flex: 2.5,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => {
        return params.row?.lender?.name ?? "";
      },
    },
    {
      headerName: columnLabels.aeStatus,
      field: "aeStatus",
      flex: 2,
      renderCell: (params) => {
        return formatAeLeadStatus(params.row.aeStatus);
      },
      disableColumnMenu: true,
      sortable: true,
    },
    {
      headerName: columnLabels.flags,
      field: "flag",
      flex: 1.5,
      renderCell: (params) => {
        return <FlagCell flag={params.row.flag || ""} />;
      },
      disableColumnMenu: true,
      sortable: true,
    },
    {
      headerName: columnLabels.daysInStatus,
      field: "daysInStatus",
      flex: 1,
      disableColumnMenu: true,
      sortable: true,
      align: "right",
      renderCell: (params) => <DaysInStatusCell daysInStatus={params.row.daysInStatus} />,
    },
    {
      headerName: columnLabels.totalDays,
      field: "totalDays",
      flex: 1,
      disableColumnMenu: true,
      sortable: true,
      align: "right",
      renderCell: (params) => {
        return formatTotalDays(params.row.totalDays);
      },
    },
  ];
  return columns;
}
