import { ActionList } from "./ActionList";
import { DashboardRow } from "../../types";
import { Dispatch, SetStateAction, useState } from "react";
import { Snackbar } from "@auto-approve/auto-approve-ui-library";
import { PanelSectionTitle } from "../PanelSectionTitle";

type ActivityPanelProps = {
    // TODO - refactor to use react query
    // This should only need to pull in the context of the currently selected row
    row: DashboardRow;
    allRows: DashboardRow[];
    setAllRows: Dispatch<SetStateAction<DashboardRow[]>>;
    handleClose?: () => void;
    showEscalateButton: boolean;
    setRequestEscalationModalOpen?: (state: boolean) => void;
};

export function ActivityPanel(props: ActivityPanelProps) {
    // TODO - create app wide snack bar provider solution
    const [snackbarState, setSnackbarState] = useState<{
        open: boolean;
        severity: "success" | "error";
        text: string;
    }>({ open: false, text: "", severity: "error" });

    return (
        <>
            <Snackbar
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
                onClose={() => setSnackbarState(state => ({ ...state, open: false }))}
                open={snackbarState.open}
                severity={snackbarState.severity}
            >
                {snackbarState.text}
            </Snackbar>
            <PanelSectionTitle aeLeadId={props.row.aeLeadId}>Activity</PanelSectionTitle>
            <ActionList
                row={props.row}
                allRows={props.allRows}
                setAllRows={props.setAllRows}
                handleClose={props.handleClose}
                showEscalateButton={props.showEscalateButton}
                setRequestEscalationModalOpen={props.setRequestEscalationModalOpen}
                handleNewAction={(_) => setSnackbarState({
                    open: true,
                    severity: "success",
                    text: "New Action Recorded"
                })
                }
            />
        </>
    );
}
