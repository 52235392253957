import { useMsal } from "@azure/msal-react";
import { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { loginRequest } from "../../msal-config";
import { loginUser, User } from "./Auth";
import { clearUserFromLocalStorage } from "./AuthHelper";
import { useLDClient } from "launchdarkly-react-client-sdk";

export interface AuthContextType {
  user: User | null;
  updateUser: (user: User) => void;
  signIn: (username: string, password: string, callback: VoidFunction) => void;
  signInWithAzure: () => void;
  logout: (callback: VoidFunction) => void;
  loginSucceeded: boolean;
  showErrorPage: boolean;
}
const AuthContext = createContext<AuthContextType>(null!);

export function useAuth(): AuthContextType {
  return useContext(AuthContext);
}

export function getUser() {
  return localStorage.getItem("user");
}

export function useRequiredAuthUser(): User {
  const user = useAuth().user;
  if (!user) throw new Error("User not authenticated");
  return user;
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(getPersistedUser());
  const [loginSucceeded, setLoginSucceeded] = useState(true);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const { instance } = useMsal();
  const ldClient = useLDClient();

  useEffect(() => {
    if (user) {
      // Identify logic added here to update user context for Launch darkly on root level, flags will have access to this context on all levels
      ldClient?.identify({
        key: user.email,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        lastName: user.lastName,
        firstName: user.firstName,
        custom: {
          role: user.role ?? "",
          teUserId: user.teUserId,
        }
      });
    }
  }, [user, ldClient]);

  const completeLogin = (tokenUser: User) => {
    setUser(tokenUser);
    setLoginSucceeded(true);
  };

  const signIn = (username: string, password: string, callback: VoidFunction) => {
    loginUser(username, password)
      .then((tokenUser) => {
        completeLogin(tokenUser);
        localStorage.setItem("user", JSON.stringify(tokenUser));
      })
      .catch((e) => {
        const shouldShowErrorPage = e.response.status !== 200 && e.response.status !== 401 && e.response.status !== 400;
        setLoginSucceeded(false);
        if (shouldShowErrorPage) {
          setShowErrorPage(true);
        }
      })
      .finally(() => {
        callback();
      });
  };

  const signInWithAzure = async () => {
    instance.loginRedirect(loginRequest);
  };

  const logout = (callback: VoidFunction) => {
    setUser(null);
    clearUserFromLocalStorage();
    callback();
  };

  function getPersistedUser(): User | null {
    const localData = localStorage.getItem("user");
    if (localData !== null) {
      return JSON.parse(localData);
    }
    return null;
  }

  function updateUser(user: User) {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  }

  const value = { user, setUser, signIn, signInWithAzure, logout, loginSucceeded, showErrorPage, updateUser };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
