import { IconButton, Tooltip } from "@mui/material";
import { PropsWithChildren } from "react";

export function IconBarButton(props: PropsWithChildren<{
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  tooltipText?: string;
  dataTestId?: string;
  dataCy?: string;
}>) {
  return (
    <Tooltip title={<>{props.tooltipText}</>}>
      <IconButton onClick={props.onClick} data-testid={props.dataTestId} data-cy={props.dataCy}>
        {props.children}
      </IconButton>
    </Tooltip>
  );
}
